<template>
  <div v-if="loadComplete" class="page_job_detail">
    <div class="job_top">
      <div class="content">
        <div class="job_card_inner">
          <div class="job_detail">
            <div class="job_title">
              <span style="flex:1">{{jobDetail.postName}}</span>
              <div v-if="jobDetail.salType === 1" class="job_salary">{{jobDetail.salLow}}-{{jobDetail.salHigh}}K <span>●{{jobDetail.salMonths}}薪</span></div>
              <div v-else-if="jobDetail.salType === 2" class="job_salary">{{userStore.SALARY_TALK}}</div>
              <div v-else-if="jobDetail.salType === 3" class="job_salary">{{jobDetail.salLow}}-{{jobDetail.salHigh}}/天</div>
            </div>
            <div class="job_brief">
              <span>{{jobDetail.cityName}}</span> | <span>{{jobDetail.workExperienceName}}</span> | <span>{{userStore.getDegree(jobDetail.education)}}</span>
            </div>
            <div class="tag_list">
              <div class="tag" :key="item" v-for="item in keywords">{{item}}</div>
            </div>
          </div>

          <div class="company_wrapper" v-if="userStore.isLogin">
            <el-button type="primary" round @click="iApply">我要应聘</el-button>
            <el-button round @click="collectJob">加入收藏</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom_content content">
      <div>
        <div class="company_detail">
          <div class="company_detail_inner">
            <div class="detail_title">职位介绍</div>
            <div class="detail_content" v-html="jobDetail.postDescription"></div>
            <div class="person">
              <img :src="jobDetail.account.avatarUrl"/>
              <div style="margin-left:5px;">
                <div class="person_name">&nbsp;</div>
                <div class="person_job">{{jobDetail.company.fullName}}·{{jobDetail.account.postName}}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="company_detail">
          <div class="company_detail_inner">
            <div class="detail_title">公司介绍</div>
            <div class="company">
              <div class="name">
                <img :src="jobDetail.company.logoUrl"/> {{jobDetail.company.fullName}}
              </div>
              <div class="brief">
                <span>{{jobDetail.company.industryClassificationName}}</span> ｜ <span>{{jobDetail.company.companyScaleName}}</span>
              </div>

            </div>
            <div class="detail_content" v-html="jobDetail.company.description"></div>
          </div>
        </div>

        <div class="company_detail">
          <div class="company_detail_inner">
            <div class="detail_title">工作地址</div>
            <div class="detail_content">
              <div class="address">
                <img src="../assets/img/icon_location.png" class="location"/> {{jobDetail.address}}
              </div>
              <div class="map_wrapper">
                <!--              <iframe src="https://uri.amap.com/marker?position=116.473195,39.993253"></iframe>-->
                <!--              <iframe src="https://apis.map.qq.com/tools/poimarker?type=1&keyword=公司&center=39.908491,116.374328&radius=1000&key=6MUBZ-TJ6WZ-OOJXL-7FUJK-DRGSQ-UTBT7&referer=job"></iframe>-->
                <div ref="map"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="company_detail">
          <div class="company_detail_inner">
            <div class="detail_title">相关职位 <a herf="javascript:void(0)" class="look_more">更多&gt;&gt;</a></div>
            <div class="detail_content card_list">
              <invite-job
                  v-for="item in relatedJobList"
                  :item="item"
                  :key="item.companyPostId"
              ></invite-job>
            </div>
          </div>
        </div>

        <div class="company_detail">
          <div class="company_detail_inner">
            <div class="detail_title">同城职位 <a href="javascript:void(0)" class="look_more">更多&gt;&gt;</a></div>
            <div class="detail_content card_list">
              <invite-job
                  v-for="item in scJobList"
                  :item="item"
                  :key="item.companyPostId"
              ></invite-job>
            </div>
          </div>
        </div>
      </div>

      <div class="content_right">
        <div class="company_wrapper">
          <div class="company_card">
            <div class="company_logo">
              <img :src="jobDetail.company.logoUrl"/>
            </div>
            <div class="name">{{jobDetail.company.fullName}}</div>
            <div class="company_item">
              <div class="item_title">企业行业：</div><div class="normal_gray">{{jobDetail.company.industryClassificationName}}</div>
            </div>
            <!--          <div class="company_item">-->
            <!--            <div class="item_title">融资阶段：</div><div class="normal_gray">金融业</div>-->
            <!--          </div>-->
            <div class="company_item">
              <div class="item_title">人数规模：</div><div class="normal_gray">{{jobDetail.company.companyScaleName}}</div>
            </div>
            <!--          <div class="company_item">-->
            <!--            <div class="item_title">企业性质：</div><div class="normal_gray">外资｜上市公司</div>-->
            <!--          </div>-->
            <div class="job_in_recruit">
              <div><span>{{jobDetail.company.postCount}}</span>个</div>
              <div>在招职位</div>
              <el-button type="primary" round @click="goCompany">查看所有职位</el-button>
            </div>
          </div>
        </div>


        <div class="invite_list">
          <div class="invite_title">同公司职位</div>
          <invite-job
              v-for="item in sameCompanyJobList"
              :item="item"
              :key="item.companyPostId"
          ></invite-job>
        </div>
        <right-ad style="margin-top:10px;"></right-ad>
        <!--      <div class="a_wrapper">-->
        <!--        <img src="../assets/img/test_ad.png"/>-->
        <!--      </div>-->
        <!--      <div class="a_wrapper">-->
        <!--        <img src="../assets/img/test_ad.png"/>-->
        <!--      </div>-->
      </div>
    </div>

    <el-dialog
        v-model="dialogVisible"
        title="我要应聘"
        width="30%"
        :close="closeDialog"
    >
      <span style="font-size:16px;">您的简历将发送该公司</span>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="opDialog('resume')">完善简历</el-button>
        <el-button type="primary" @click="opDialog('send')">
          确认发送
        </el-button>
      </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import inviteJob from "@/components/inviteJob";
import RightAd from "@/components/rightAd";
import {getPostDetail, relatedJobList, sameCompanyJobList} from "@/api/user/user";
import {applyJob,collectJob,sameCityJobList} from "@/api/user/user";

import { mapStores } from 'pinia';
import {useUserStore} from "@/store/user/user";
import {keywordSplit} from "@/util/util";

export default {
  name: "jobDetail",
  components:{
    RightAd,
    inviteJob
  },
  data(){
    return {
      jobDetail:{

      },
      map:"",
      dialogVisible:false,
      loadComplete:false,
      keywords:[],
      scJobList:[],
      relatedJobList:[],
      sameCityJobList:[],
      sameCompanyJobList:[],
    }
  },
  methods:{
    closeDialog(){
      this.dialogVisible = false;
    },
    opDialog(act){
      if(act === "send"){
        this.applyJob();
      }else if(act === "resume"){
        this.$router.push({
          path:"/resume"
        });
      }
      this.dialogVisible=false;
    },
    iApply(){
      if(this.jobDetail.applyUrl === null || this.jobDetail.applyUrl === ""){
        this.dialogVisible = true
      }else{
        window.open(this.jobDetail.applyUrl);
      }
    },
    goCompany(){
      this.$router.push({
        path:"/companyDetail",
        query:{
          id:this.jobDetail.company.companyId
        }
      });
    },
    collectJob(){
      if(!this.userStore.isLogin){
        this.$message({
          type:"error",
          message:"请在首页登录"
        });
        return ;
      }
      collectJob({
        companyPostId:this.$route.query.id
      }).then(res => {
        if(res.code === 0){
          this.$message({
            type:"success",
            message:"收藏成功"
          })
        }else{
          this.$message({
            type:"error",
            message:res.msg
          });
        }
      });
    },
    applyJob(){
      if(!this.userStore.isLogin){
        this.$message({
          type:"error",
          message:"请在首页登录"
        });
        return ;
      }
      applyJob({
        companyPostId:this.$route.query.id,
        type:0
      }).then(res => {
        if(res.code === 0){
          this.$message({
            type:"success",
            message:"发送应聘信息成功"
          })
        }
      });
    },
    initMap(){
      if(this.map !==""){
        return ;
      }
      var center = new TMap.LatLng(39.984120, 116.307484)
      //定义map变量，调用 TMap.Map() 构造函数创建地图
      this.map = new TMap.Map(this.$refs.map, {
        center: center,//设置地图中心点坐标
        zoom: 17.2,   //设置地图缩放级别
        pitch: 43.5,  //设置俯仰角
        rotation: 45    //设置地图旋转角度
      });
      new TMap.MultiMarker({
        map: this.map,
        styles: {
          // 点标记样式
          marker: new TMap.MarkerStyle({
            width: 20, // 样式宽
            height: 30, // 样式高
            anchor: { x: 10, y: 30 }, // 描点位置
          }),
        },
        geometries: [
          // 点标记数据数组
          {
            // 标记位置(纬度，经度，高度)
            position: center,
            id: 'marker',
          },
        ],
      });
    },
    getJobById(){
      getPostDetail({
        companyPostId:this.$route.query.id
      }).then(res => {
        if(res.code === 0){
          res.data.account = res.data.account?res.data.account:{}
          this.jobDetail = res.data;
          this.keywords = res.data.keyWords===""?[]:keywordSplit(res.data.keyWords)
          this.loadComplete = true;
        }
      });

      sameCityJobList({
        companyPostId:this.$route.query.id,
        pageNum:1,
        pageSize:12
      }).then(res => {
        console.log(res)
        if(res.code === 0){
          this.scJobList = res.data.rows;
        }
      });
      relatedJobList({
        companyPostId:this.$route.query.id,
        pageNum:1,
        pageSize:12
      }).then(res => {
        if(res.code === 0){
          this.relatedJobList = res.data.rows;
        }
      });
      sameCompanyJobList({
        companyPostId:this.$route.query.id,
        pageNum:1,
        pageSize:12
      }).then(res => {
        if(res.code === 0){
          this.sameCompanyJobList = res.data.rows;
        }
      });
    }
  },
  watch:{
    "$route.query.id":{
      handler(v){
        this.getJobById();
      }
    }
  },
  mounted(){
    if(this.$route.query.id){
      this.getJobById()
    }

    setTimeout(()=>{
      this.initMap();
    },300)

  },
  computed:{
    ...mapStores(useUserStore)
  },
}
</script>

<style lang="less">
@import "../assets/css/variable";
.page_job_detail{
  .invite_list{
    margin-top:10px;
    background-color:#fff;
    padding-top:10px;
    overflow: hidden;
    .invite_title{
      font-size: 20px;
      line-height: 60px;
      height:60px;
      color: @blue_color;
      text-align: center;
      margin-bottom:10px;
    }
    .invite_job{
      margin-left:25px;
      margin-bottom:30px;
    }
  }
  .job_top{
    min-height:164px;
    overflow: hidden;
    background-color:#fff;
    .company_wrapper{
      display:flex;
      align-items: center;
      justify-content: space-around;
    }
    .job_card_inner{
      margin:25px;
      position:relative;
      display:flex;
      justify-content: space-between;
    }
    .job_title{
      font-size: 28px;
      font-weight:bold;
      color: #1a1a1a;
      display:flex;
      align-items: center;
      max-width:1200px;
      justify-content: space-between;
      .job_salary{
        color:#ed6e2d;
        width:180px;
        margin-left:15px;
      }
    }
    .job_brief{
      font-size: 14px;
      color: #999999;
      margin-top:15px;
    }
    .el-button.is-round{
      width:120px;
      height:45px;
      border-radius:25px;
      font-size:16px;
      padding:0;
      text-align: center;
    }
  }
  .company_wrapper{
    background-color:#fff;
    overflow: hidden;
  }
  .company_card{
    margin:40px 20px 20px;
    .company_logo{
      text-align: center;
    }
    img{
      height:60px;
    }
    .name{
      font-size: 18px;
      text-align: center;
      color: #1a1a1a;
      margin-bottom:40px;
    }
    .company_item{
      display:flex;
      margin-bottom:10px;
      .item_title{
        margin-right:5px;
        font-size:14px;
        color:#1a1a1a;
      }
    }
    .job_in_recruit{
      font-size:17px;
      color:#1a1a1a;
      text-align: center;
      margin-top:40px;
      span{
        color:@blue_color;
      }
      .el-button{
        margin:30px auto 20px;
        width:160px;
        height:45px;
        span{
          color:#fff;
          font-size:16px;
        }

      }
    }
  }
  .bottom_content{
    display:flex;
    justify-content: space-between;
    margin-top:20px;
    .company{
      display: flex;
      margin-bottom:35px;
      .name{
        font-size: 14px;
        color: #1a1a1a;
        display:flex;
        align-items: center;
      }
      .brief{
        font-size: 14px;
        margin-left:50px;
        color: #999999;
      }
      img{
        width:23px;
        margin-right:5px;
      }
    }
    .person{
      margin-top:40px;
      display:flex;
      .person_name{
        font-size: 16px;
        color: #1a1a1a;
      }
      .person_job{
        font-size: 14px;
        color: #999999;
      }
      img{
        width:60px;
        height:60px;
        border-radius:60px;
      }
    }
    .card_list{
      display:flex;
      flex-wrap: wrap;
      .invite_job{
        width:252px;
        height:86px;
        overflow: hidden;
        border-radius: 10px;
        margin-right:10px;
        margin-bottom:15px;
        border:1px solid #f5f2f2;
        &:nth-child(3n){
          margin-right:0;
        }
        .job_title{
          margin:15px 15px 0;
        }
        .company{
          margin:13px 15px 0;
        }
      }
    }
    .a_wrapper:first-child{
      margin-top:0;
    }
    .address{
      font-size: 15px;
      color: #333333;
      display: flex;
      align-items: center;
    }
    .location{
      width:13px;
      margin-right:5px;
    }
    .map_wrapper{
      margin-top:10px;
      iframe{
        width:784px;
        height:400px;
        border:none;
      }
    }
    .company_detail{
      background-color:#fff;
      overflow: hidden;
      width:862px;
      margin-bottom:15px;
    }
    .detail_title{
      font-size: 19px;
      color: #1a1a1a;
      margin:30px 0;
      display: flex;
      justify-content: space-between;
      .look_more{
        font-size: 16px;
        color: #00b0b1;
      }
    }
    .detail_content{
      font-size: 15px;
      color: #333333;
    }
    .company_detail_inner{
      margin:40px;
    }
  }
}
</style>
